import { Link } from "gatsby"
import React from "react"
import Navigation from "./navigation"

const Header = () => (
  <header>
    <div className="mt-5 text-center">
      <Link to="/">
        <img
          src="/images/logo.jpg"
          width="400"
          alt="logo"
          className="inline-block"
        />
      </Link>
    </div>
    <Navigation />
  </header>
)

export default Header
