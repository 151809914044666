import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faYoutubeSquare } from "@fortawesome/free-brands-svg-icons"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="flex flex-col min-h-screen mx-10 lg:w-3/4 lg:mx-auto">
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className="flex-grow">{children}</main>
      <footer className="flex justify-between py-5 border-t">
        <span className="inline-block mr-5 text-xs italic">
          California Cities for Local Control - {new Date().getFullYear()}
        </span>
        <span>
          <a
            href="https://www.youtube.com/channel/UC6sx0NOQOTez_uwFK6dClgQ/featured"
            target="_blank"
            rel="noreferrer"
            title="For more videos, please visit our YouTube channel."
          >
            <FontAwesomeIcon icon={faYoutubeSquare} size="2x" />
          </a>
        </span>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
