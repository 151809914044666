import { Link } from "gatsby"
import React from "react"

const Navigation = () => (
  <nav className="flex flex-col mt-8 mb-5 lg:flex-row lg:justify-evenly">
    <Link to="/" className="text-center" activeClassName="active">
      Home
    </Link>
    <Link
      to="/elected-officials"
      className="text-center"
      activeClassName="active"
    >
      Elected Officials
    </Link>
    <Link to="/resolutions" className="text-center" activeClassName="active">
      Resolutions
    </Link>
    <Link to="/voting-record" className="text-center" activeClassName="active">
      Voting Record
    </Link>
    <Link
      to="/position-letters"
      className="text-center"
      activeClassName="active"
    >
      Position Letters
    </Link>
    <Link to="/map" className="text-center" activeClassName="active">
      Interactive Map
    </Link>
    <Link to="/more-info" className="text-center" activeClassName="active">
      More Info
    </Link>
    <Link to="/contact" className="text-center" activeClassName="active">
      Contact
    </Link>
  </nav>
)

export default Navigation
